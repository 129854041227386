<template>
  <div>
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />
      <AccountForm :data="getValueInputs" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import AccountForm from "@/components/account-form/AccountForm.vue";

export default {
  components: {
    Breadcrumbs,
    Panel,
    AccountForm,
  },
  data() {
    return {
      regras: [],
      panel: [],
      accountform: [],
      location: "",
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.location = "Minha conta";
    },
  },
  computed: {
    getValueInputs() {
      let res = this.$store.state.user.dadosCompletos;
      let data = {};

      if (res) {
        data.name = res.name;
        data.email = res.email;
        data.telephone = res.telephone;
        data.document = res.document;
        data.perfil = res.user_role.label;
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.setup {
  height: 100%;

  .page-container {
    margin: 30px 0;
  }
}

.content {
  display: flex;
}
.box {
  margin-top: 0 !important;
}
</style>
