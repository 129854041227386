<template>
  <div class="accountform" :class="profile && profile.company_data ? '' : 'mt-none'">
    <div class="box">
      <form>
        <div class="field">
          <label for="nome">Nome</label>
          <input type="text" v-bind:value="name ? name : (name = getName)" v-on:input="name = $event.target.value" required />
        </div>

        <div class="field">
          <label for="e-mail">E-mail</label>
          <input
            type="text"
            v-bind:value="email ? email : (email = getEmail)"
            v-on:input="email = $event.target.value"
            required
            disabled
          />
        </div>

        <div class="field">
          <label for="telefone">Telefone</label>
          <input
            type="text"
            v-mask="['(##) ####-#####']"
            v-bind:value="telephone ? telephone : (telephone = getTelephone)"
            v-on:input="telephone = $event.target.value"
            required
          />
        </div>

        <div class="field">
          <label for="cpf">CPF</label>
          <input
            type="text"
            v-mask="['###.###.###-##']"
            v-bind:value="document ? document : (document = getDocument)"
            v-on:input="document = $event.target.value"
            required
          />
        </div>

        <div class="field">
          <label for="perfil">Perfil</label>
          <input
            type="text"
            v-bind:value="perfil ? perfil : (perfil = getPerfil)"
            v-on:input="perfil = $event.target.value"
            required
            disabled
          />
        </div>
      </form>

      <div class="button-box">
        <div v-on:click="handleSave" class="save">
          <a href="#">Salvar alterações</a>
        </div>
      </div>

      <small class="notice">
        Para cancelar a sua conta, entre em contato com o e-mail
        <a href="mailto:contato@vdash.com.br">contato@vdash.com.br.</a>
      </small>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UpdatePerfil } from "@/api/profile";

export default {
  props: ["data"],
  data() {
    return {
      name: "",
      email: "",
      telephone: "",
      document: "",
      perfil: "",
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleSave() {
      UpdatePerfil({
        name: this.name,
        email: this.email,
        telephone: this.telephone,
        document: this.document,
        perfil: this.perfil,
      });
    },
  },
  computed: {
    ...mapState({
      profile: state => state.user.dadosCompletos,
    }),
    getName() {
      let res = this.data.name;
      return res;
    },
    getEmail() {
      let res = this.data.email;
      return res;
    },
    getTelephone() {
      let res = this.data.telephone;
      return res;
    },
    getDocument() {
      let res = this.data.document;
      return res;
    },
    getPerfil() {
      let res = this.data.perfil;
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.accountform {
  max-width: 1342px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 18px;
  margin-top: 65px;
  .box {
    form {
      display: flex;
      max-height: 500px;
      flex-wrap: wrap;
      max-width: 800px;
      width: 100%;
      margin-bottom: 100px;
      .field {
        display: flex;
        flex-direction: column;
        max-width: calc(50% - 15px);
        width: 100%;
        margin: 7.5px;

        label {
          font-size: 16px;
          font-weight: bolder;
          font-family: $font__main;
          margin-bottom: 10px;
        }

        input {
          max-width: 361px;
          width: 100%;
          height: 49px;
        }
      }
    }

    .button-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .save {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 335px;
        width: 100%;
        height: 51px;
        border-radius: 5px;
        background-color: #73cb53;
        margin: 0;
        a {
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          font-family: $font__main;
        }
      }

      .cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 335px;
        width: 100%;
        height: 51px;
        border-radius: 5px;
        background-color: #ffffff;
        border: solid 1px;
        border-color: #707070;
        margin: 7.5px;

        a {
          color: #ff0000;
          font-size: 16px;
          font-weight: bold;
          font-family: $font__main;
        }
      }
    }

    .notice {
      margin-top: 25px;
      font-size: 12px;
      display: inline-block;
      a {
        &:hover {
          color: #ff2e63;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .accountform {
    padding: 20px 25px;
    .box {
      form {
        flex-direction: column;
        margin-bottom: 50px;
        flex-wrap: nowrap;
        max-height: inherit;
        .field {
          margin: 0;
          max-width: 100%;
          margin-bottom: 10px;
          input {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .accountform {
    display: flex;
    flex-direction: column;

    .box {
      .button-box {
        display: flex;
        align-items: center;
        flex-direction: column;

        .save {
          max-width: 500px;
          margin-right: 0px;
          margin-bottom: 15px;
        }

        .cancel {
          max-width: 500px;
        }
      }
    }
  }
}
</style>
